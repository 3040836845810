<template>
  <main>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      class="supplier-sidebar"
      fixed
      v-if="!hideNavigations"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="@/assets/img/logo.svg"></v-img>
        </v-list-item-avatar>

        <!-- <v-list-item-title>Wegang</v-list-item-title> -->
      </v-list-item>

      <v-divider></v-divider>
      <v-list class="top-list-sidebar">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          router
          exact
        >
          <v-list-item-action>
            <v-icon class="supplier-menu-icon">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="dark-menu-item" v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template #append>
        <v-list class="bottom-list-sidebar">
          <v-list-item
            v-for="(item, i) in bottom_items"
            :key="i"
            :to="item.to"
            router
            exact
          >
            <v-list-item-action>
              <v-icon class="supplier-menu-icon">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="dark-menu-item" v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <div v-if="!isIframe">
      <v-app-bar
        :clipped-left="clipped"
        fixed
        app
        class="app-bar"
        :class="{ isOpen: miniVariant }"
        v-if="!hideNavigations"
      >
        <v-btn icon @click.stop="miniVariant = !miniVariant">
          <v-icon>mdi-{{ `chevron-${miniVariant ? "right" : "left"}` }}</v-icon>
        </v-btn>
        <v-toolbar-title v-text="$route.name" />
        <v-spacer />
      </v-app-bar>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isIframe: false,
      miniVariant: true,
      userType: "supplier",
      hideNavigations: false,
      clipped: false,
      drawer: true,
      fixed: false,
      items: [
        {
          icon: "mdi-home",
          title: "Dashboard",
          to: "/supplier",
        },
        // {
        //   icon: "mdi-cart",
        //   title: "Order List",
        //   to: "/supplier/order-list",
        // },
        // {
        //   icon: "mdi-view-dashboard",
        //   title: "My products",
        //   to: "/my-products",
        // },
        // {
        //   icon: " mdi-credit-card",
        //   title: "Checkout",
        //   to: "/checkout",
        // },
      ],
      bottom_items: [
        // {
        //   icon: "mdi-book-open-blank-variant",
        //   title: "Help Center",
        //   to: "/onboarding",
        // },
        {
          icon: "mdi-cog",
          title: "Setting",
          to: "/supplier/setting",
        },
      ],
      right: false,
      rightDrawer: false,
      title: "Vgang",
    };
  },
  mounted() {
    this.checkForAuthRoutes();
    if (process.env.VUE_APP_SOURCE_NAME == "shopifyimporter") {
      this.isIframe = true;
    } else {
      this.isIframe = false;
    }
  },
  methods: {
    checkForAuthRoutes() {
      if (this.$route.name == "RegisterSupplier") {
        this.hideNavigations = true;
      } else if (this.$route.name == "RegisterRetailer") {
        this.hideNavigations = true;
      } else if (this.$route.name == "LoginRetailer") {
        this.hideNavigations = true;
      } else if (this.$route.name == "LoginSupplier") {
        this.hideNavigations = true;
      }
    },
  },
};
</script>

<style></style>
