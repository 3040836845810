import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
  getOrders(pagination) {
    return api.get(
      "sellers/orders",
      { params: pagination },
      { headers: authHeader() }
    );
  },

  getOrder(id) {
    return api.get("sellers/orders/" + id, {}, { headers: authHeader() });
  },
};
