<template>
  <main>
    <div v-if="isShopify">
      <div class="page-wrapper is-full-screen">
        <div class="order-detail-page">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-3">
                <div class="order-status-box">
                  <h1 class="order-title" style="margin-bottom: 20px">
                    Order Num: {{ order.external_id }}
                  </h1>
                  <span
                    v-if="order.status == 1"
                    class="Polaris-Badge Polaris-Badge--statusSuccess"
                    ><span class="Polaris-VisuallyHidden">Paid</span>Paid</span
                  >
                  <span
                    v-if="order.status == 2"
                    class="Polaris-Badge Polaris-Badge--statusSuccess Polaris-Badge--progressComplete"
                    ><span class="Polaris-Badge__Pip"
                      ><span class="Polaris-VisuallyHidden"
                        >Delivered To Post</span
                      ></span
                    >Delivered To Post</span
                  >
                  <span
                    v-if="order.status == 0"
                    class="Polaris-Badge Polaris-Badge--statusInfo"
                    ><span class="Polaris-VisuallyHidden">Pending </span
                    >Pending</span
                  >
                  <div class="delivery-counter">
                    <span class="s-title"
                      >Time left to deliver to the post</span
                    >
                    <div
                      class="counter"
                      v-html="secondsToDhms(order.delivery_time_remaining)"
                    ></div>
                  </div>
                  <div
                    v-if="
                      order.status_history && order.status_history.length > 0
                    "
                    class="delivery-steps"
                  >
                    <ul class="ul">
                      <li class="delivery-step">
                        <div
                          class="circle"
                          :class="{ active: order.status_history.length == 1 }"
                        ></div>
                        <div class="step">
                          <span class="d-title">Order placed</span>
                          <span class="d-subtitle">{{
                            formatDate(order.status_history[0].created_at)
                          }}</span>
                        </div>
                      </li>
                      <div class="line"></div>
                      <li class="delivery-step">
                        <div
                          class="circle"
                          :class="{ active: order.status_history.length == 2 }"
                        ></div>
                        <div class="step">
                          <span class="d-title">Checkout by retailer</span>
                          <span
                            class="d-subtitle"
                            v-if="order.status_history.length == 2"
                            >{{
                              formatDate(order.status_history[1].created_at)
                            }}</span
                          >
                        </div>
                      </li>
                      <div class="line dashed"></div>
                      <li class="delivery-step">
                        <div
                          class="circle"
                          :class="{ active: order.status_history.length == 3 }"
                        ></div>
                        <div
                          v-if="order.status_history[0].created_at"
                          class="step"
                        >
                          <span class="d-title">Deliver to post</span>
                          <span class="d-subtitle"
                            >Expected date:
                            {{
                              formatDate(
                                addRemainingSecondsToDate(
                                  formatDate(
                                    order.status_history[0].created_at
                                  ),
                                  order.delivery_time_remaining
                                )
                              )
                            }}
                          </span>
                        </div>
                      </li>

                      <div class="line dashed"></div>
                      <li class="delivery-step">
                        <div
                          class="circle"
                          :class="{ active: order.status_history.length == 4 }"
                        ></div>
                        <div class="step">
                          <span class="d-title">Paid & completed</span>
                          <span
                            class="d-subtitle"
                            v-if="order.status_history.length == 4"
                            >{{
                              formatDate(order.status_history[3].created_at)
                            }}</span
                          >
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-sm-9">
                <div class="order-detail-box">
                  <div class="each-detail-section">
                    <h2 class="detail-title">Order Details</h2>
                    <table class="orderDetail-table">
                      <thead class="header">
                        <tr>
                          <th>Order No.</th>
                          <th>Order date</th>
                          <th>Discount</th>
                          <th>Order total</th>
                          <th>Total</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody class="body">
                        <tr class="each-row">
                          <td>{{ order.external_id }}</td>
                          <td>{{ orderCreatedAt }}</td>
                          <td>{{ order.total_disscount }}</td>
                          <td>{{ order.total_price }}</td>
                          <td>{{ order.total_price }}</td>
                          <td>
                            <span
                              v-if="order.status == 1"
                              class="Polaris-Badge Polaris-Badge--statusSuccess"
                            >
                              <span class="Polaris-VisuallyHidden">Paid</span>
                              Paid
                            </span>

                            <span
                              v-if="order.status == 2"
                              class="Polaris-Badge Polaris-Badge--statusSuccess Polaris-Badge--progressComplete"
                              ><span class="Polaris-Badge__Pip"
                                ><span class="Polaris-VisuallyHidden"
                                  >Delivered To Post</span
                                ></span
                              >Delivered To Post</span
                            >
                            <span
                              v-if="order.status == 0"
                              class="Polaris-Badge Polaris-Badge--statusInfo"
                              ><span class="Polaris-VisuallyHidden"
                                >Pending </span
                              >Pending</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="each-detail-section">
                    <h2 class="detail-title">Cart Details</h2>
                    <table class="orderDetail-table">
                      <thead class="header">
                        <tr>
                          <th style="padding-right: 40px">Index</th>
                          <th>Product title</th>
                          <th style="padding-right: 120px">Qnt.</th>
                        </tr>
                      </thead>
                      <tbody class="body">
                        <tr
                          class="each-row"
                          v-for="(line, i) in order.lines"
                          :key="i"
                        >
                          <td>{{ i }}</td>
                          <td>
                            {{ line.title }}
                          </td>
                          <td>{{ line.quantity }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="each-detail-section">
                    <h2 class="detail-title">Shipping Info.</h2>
                    <table class="orderDetail-table" style="margin-bottom: 0">
                      <thead class="header">
                        <tr>
                          <th>Customer name</th>
                          <th>Cellnumber</th>
                          <th>Email address</th>
                          <th>Ship To</th>
                        </tr>
                      </thead>
                      <tbody class="body">
                        <tr class="each-row">
                          <td>
                            {{
                              order.customer.name ? order.customer.name : "-"
                            }}
                          </td>
                          <td>
                            {{
                              order.customer.phone ? order.customer.phone : "-"
                            }}
                          </td>
                          <td>
                            {{
                              order.customer.email ? order.customer.email : "-"
                            }}
                          </td>
                          <td>
                            {{
                              order.customer.city ? order.customer.city : "-"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              order.customer.address
                                ? order.customer.address
                                : "-"
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="np-footer">
            <a href="https://vgang.io/partnership" class="_link" target="_blank"
              >VGANG for suppliers</a
            >
            <a href="https://vgang.io/terms" class="_link" target="_blank"
              >Terms & Conditions</a
            >
            <a href="https://vgang.io/about-us" class="_link" target="_blank"
              >About us</a
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isShopify">
      <div v-if="userType == 'supplier'">
        <supplierNavigation />
        <v-main app>
          <div class="page-wrapper">
            <!-- ======================== Order List Table ============================= -->
            <div class="order-detail-page">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="order-status-box">
                      <h1 class="order-title" style="margin-bottom: 20px">
                        Order Num: {{ order.external_id }}
                      </h1>
                      <span class="status" :class="orderStatus">
                        {{ orderStatus }}</span
                      >
                      <div class="delivery-counter">
                        <span class="s-title"
                          >Time left to deliver to the post</span
                        >
                        <div
                          class="counter"
                          v-html="secondsToDhms(order.delivery_time_remaining)"
                        ></div>
                      </div>
                      <div
                        v-if="
                          order.status_history &&
                          order.status_history.length > 0
                        "
                        class="delivery-steps"
                      >
                        <ul class="ul">
                          <li class="delivery-step">
                            <div
                              class="circle"
                              :class="{
                                active: order.status_history.length == 1,
                              }"
                            ></div>
                            <div class="step">
                              <span class="d-title">Order placed</span>
                              <span class="d-subtitle">{{
                                formatDate(order.status_history[0].created_at)
                              }}</span>
                            </div>
                          </li>
                          <div class="line"></div>
                          <li class="delivery-step">
                            <div
                              class="circle"
                              :class="{
                                active: order.status_history.length == 2,
                              }"
                            ></div>
                            <div class="step">
                              <span class="d-title">Checkout by retailer</span>
                              <span
                                class="d-subtitle"
                                v-if="order.status_history.length == 2"
                                >{{
                                  formatDate(order.status_history[1].created_at)
                                }}</span
                              >
                            </div>
                          </li>
                          <div class="line dashed"></div>
                          <li class="delivery-step">
                            <div
                              class="circle"
                              :class="{
                                active: order.status_history.length == 3,
                              }"
                            ></div>
                            <div class="step">
                              <span class="d-title">Deliver to post</span>
                              <span class="d-subtitle"
                                >Expected date:
                                {{
                                  formatDate(
                                    addRemainingSecondsToDate(
                                      formatDate(
                                        order.status_history[0].created_at
                                      ),
                                      order.delivery_time_remaining
                                    )
                                  )
                                }}
                              </span>
                            </div>
                          </li>

                          <div class="line dashed"></div>
                          <li class="delivery-step">
                            <div
                              class="circle"
                              :class="{
                                active: order.status_history.length == 4,
                              }"
                            ></div>
                            <div class="step">
                              <span class="d-title">Paid & completed</span>
                              <span
                                class="d-subtitle"
                                v-if="order.status_history.length == 4"
                                >{{
                                  formatDate(order.status_history[3].created_at)
                                }}</span
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-9">
                    <div class="order-detail-box">
                      <div class="each-detail-section">
                        <h2 class="detail-title">Order Details</h2>
                        <table class="orderDetail-table">
                          <thead class="header">
                            <tr>
                              <th>Order No.</th>
                              <th>Order date</th>
                              <th>Shipping cost</th>
                              <th>Order total</th>
                              <th>Total</th>
                              <th v-if="order.status">Status</th>
                            </tr>
                          </thead>
                          <tbody class="body">
                            <tr class="each-row">
                              <td>{{ order.external_id }}</td>
                              <td>{{ orderCreatedAt }}</td>
                              <td>{{ order.total_disscount }}</td>
                              <td>{{ order.total_price }}</td>
                              <td>{{ order.total_price }}</td>
                              <td v-if="order.status">
                                <span class="status" :class="orderStatus">
                                  {{ orderStatus }}</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="each-detail-section">
                        <h2 class="detail-title">Shipping Info.</h2>
                        <table
                          class="orderDetail-table"
                          style="margin-bottom: 0"
                        >
                          <thead class="header">
                            <tr>
                              <th>Customer name</th>
                              <th>Cellnumber</th>
                              <th>Email address</th>
                              <th>City</th>
                              <th>Ship To</th>
                            </tr>
                          </thead>
                          <tbody class="body">
                            <tr class="each-row">
                              <td>
                                {{
                                  order.customer.name
                                    ? order.customer.name
                                    : "-"
                                }}
                              </td>
                              <td>
                                {{
                                  order.customer.phone
                                    ? order.customer.phone
                                    : "-"
                                }}
                              </td>
                              <td>
                                {{
                                  order.customer.email
                                    ? order.customer.email
                                    : "-"
                                }}
                              </td>
                              <td>
                                {{
                                  order.customer.city
                                    ? order.customer.city
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {{
                                  order.customer.address
                                    ? order.customer.address
                                    : "-"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ======================== Order List Table ============================= -->
          </div>
        </v-main>
      </div>
    </div>
  </main>
</template>
<script>
// Services
import CommonData from "@/services/CommonData";
import supplierNavigation from "@/components/navigation/supplier.vue";
import SupplierOrders from "@/services/SupplierOrders";
import { setVariable } from "@/utils/conditionalStoreVariables";
export default {
  components: { supplierNavigation },
  data() {
    return {
      userType: "supplier",
      listOfOrders: [],
      isShopify: false,
      id: "",
      order: "",
    };
  },
  mounted() {
    this.getOrder(this.$route.params.id);

    if (process.env.VUE_APP_SOURCE_NAME == "shopifyimporter") {
      this.isShopify = true;
    } else {
      CommonData.getCurrentUser()
        .then(function (response) {
          this.userType = response.data.role;

          setVariable("role", this.userType);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  computed: {
    orderStatus() {
      let status = "-";
      switch (this.order.status) {
        case 0:
          status = "pending";
          break;
        case 1:
          status = "paid";
          break;
        case 2:
          status = "delivered-to-post";
          break;
      }
      return status;
    },
    orderCreatedAt() {
      var d = new Date(this.order.created_at);
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },
  methods: {
    getOrder(id) {
      SupplierOrders.getOrder(id).then((response) => {
        this.order = response.data;
        console.log(this.order);
      });
    },
    mapStatuses(status) {
      switch (status) {
        case "Created":
          return "pending";
        case "PaidInFull":
          return "paid";
        case "Fullfilled":
          return "delivered-to-post";
      }
    },
    formatDate(date) {
      var d = new Date(date);
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    secondsToDhms(seconds) {
      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);

      var dDisplay = d > 0 ? (d == 1 ? " Day " : " Days ") : "";
      var hDisplay = h > 0 ? (h == 1 ? " Hour " : " Hours ") : "";
      var mDisplay = m > 0 ? (m == 1 ? " Min " : " Mins ") : "";
      return `<div class="segment">
          <span class="number">${d}</span>
          <span class="text">${dDisplay}</span>
        </div>
        
        <div class="segment">
          <span class="number">${h}</span>
          <span class="text">${hDisplay}</span>
        </div>
        
        <div class="segment">
            <span class="number">${m}</span>
          <span class="text">${mDisplay}</span>
        </div>
        `;
    },
    addRemainingSecondsToDate(date, seconds) {
      var d = new Date(date);
      d.setSeconds(d.getSeconds() + seconds);
      return d;
    },
  },
};
</script>

<style lang="scss">
.no-access {
  border-radius: 10px;
  text-align: center;
  color: #ff0a43;
  padding: 30px;
  border: 1px solid #ff0a43;
}
.order-detail-page {
  .orderDetail-table {
    width: 100%;
    background: #f3f5f7;
    margin-bottom: 32px;
    th {
      padding-top: 26px;
      padding-bottom: 26px;
      border-bottom: 1px solid #ededed;
      text-align: left !important;
      padding-left: 24px;
    }
    td {
      padding-top: 26px;
      padding-bottom: 26px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #111418;
      text-align: left !important;
      padding-left: 24px;
    }
    .each-row {
      border-bottom: 1px solid #ededed;
      &:last-child {
        border-bottom: 1px solid transparent;
      }
      .button {
        border: 1px solid #008f4a;
        color: #008f4a;
        border-radius: 10px;
        padding: 10px 24px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
    .status {
      font-size: 12px;
      padding: 5px 10px;
      .status-icon {
        vertical-align: text-bottom;
        margin-right: 5px;
        display: inline-block;
        font-size: 18px;
      }
      &.waiting {
        color: white;
        background: #00e676;
        border-radius: 5px;
      }
      &.delivered-to-post {
        color: white;
        background: #0ac2ff;
        border-radius: 5px;
      }
      &.pending {
        color: white;
        background: #ff7e9d;
        border-radius: 5px;
      }
      &.paid {
        color: #008f4a;
        border-radius: 5px;
        border: 1px solid #008f4a;
      }
    }
  }
}

.order-status-box {
  padding: 24px;
  background: white;
  border-radius: 10px;
  .order-title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111418;
  }
  .delivery-counter {
    margin-top: 44px;
    .s-title {
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #111418;
    }
    .counter {
      margin-top: 8px;
      margin-bottom: 35px;
      display: flex;
      justify-content: space-between;
      padding: 10px 14px 8px 12px;
      border-radius: 5px;
      background-color: #1f2936;
      width: 231px;
      .segment {
        color: white;
        .number {
          font-size: 32px;
          font-weight: 800;
          margin-right: 2px;
        }
        .text {
          font-size: 8px;
          font-weight: 600;
        }
      }
    }
  }
  .delivery-steps {
    .ul {
      padding: 0;
      margin: 0;
      list-style: none;
      .delivery-step {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .circle {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background: #008f4a;
          margin-right: 20px;
          background: white;
          border: 4px solid #008f4a;
          &.active {
            background: #008f4a;
          }
        }
        .step {
          display: flex;
          flex-direction: column;
          .d-title {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #111418;
          }
          .d-subtitle {
            font-size: 10px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: #435160;
          }
        }
      }
      .line {
        width: 10px;
        height: 35px;
        border-left: 2px solid #008f4a;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        &.dashed {
          border-left: 2px dashed #435160;
        }
      }
      .track-code-container {
        margin-top: 24px;
        margin-bottom: 24px;
        .track-input {
          margin-bottom: 12px;
          padding: 10px !important;
        }
        .track-button {
          padding: 12px !important;
        }
      }
    }
  }
  .status {
    font-size: 12px;
    padding: 5px 10px;
    .status-icon {
      vertical-align: text-bottom;
      margin-right: 5px;
      display: inline-block;
      font-size: 18px;
    }
    &.waiting {
      color: white;
      background: #00e676;
      border-radius: 5px;
    }
    &.delivered-to-post {
      color: white;
      background: #0ac2ff;
      border-radius: 5px;
    }
    &.pending {
      color: white;
      background: #ff7e9d;
      border-radius: 5px;
    }
    &.paid {
      color: #008f4a;
      border-radius: 5px;
      border: 1px solid #008f4a;
    }
  }
}
.order-detail-box {
  background: white;
  border-radius: 10px;
  box-shadow: 0 20px 50px 10px rgba(29, 41, 54, 0.1);
  overflow: scroll;
  height: 90vh;
  .detail-title {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d2936;
    padding-left: 24px;
    padding-bottom: 24px;
    margin: 0;
    padding-top: 24px;
  }
}
.np-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f7;
  padding: 20px;
  margin-top: 180px !important;
  ._link {
    margin-left: 32px;
    margin-right: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #435160;
  }
}
</style>
